import React from "react";
import * as Joi from "joi-browser";
import ApiResourceHelper from "../../../../helpers/ApiResourceHelper";
import Form from "../../../Form";
import axios from "axios";

class BrochureRequestForm extends Form {
  state = {
    data: {
      model: "",
      gender: "",
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      city: "",
      acceptedMarketingActivity: "",
    },
    isDisclaimerOpen: false,
    errors: {},
    cities: [],
    selectedModel: {},
    loading: false,
  };

  schema = {
    gender: Joi.string().required().label("Civilité"),
    lastName: Joi.string().required().min(2).max(30).label("Nom"),
    firstName: Joi.string().required().min(2).max(30).label("Prénom"),
    email: Joi.string().required().email().label("Email"),
    telephone: Joi.string()
      .required()
      .regex(/^(0)([0-9]){9}$/i)
      .label("Téléphone"),
    city: Joi.string().required().label("Ville"),
    model: Joi.string().allow("", null).label("Modèle"),
    acceptedMarketingActivity: Joi.boolean()
      .required()
      .label("J'accepte l'activité du marketing"),
  };

  // doSubmit = async () => {
  //     //console.log("Request form submitted");
  //     //console.log("Request form data", this.state.data);
  //
  //     const {data} = this.state;
  //
  //     const {model: currentModel, form} = this.props;
  //     data.model = `/models/${currentModel.id}`;
  //
  //     if (data.city === "") {
  //         delete data.city;
  //     }
  //
  //     if (data.telephone === "") {
  //         delete data.telephone;
  //     }
  //
  //     const apiHelper = new ApiResourceHelper(form.endpoint);
  //     const {data: request} = await apiHelper.create(data);
  //     //console.log("created");
  //
  //     const {onComplete, setRequest} = this.props;
  //     setRequest(request);
  //     onComplete();
  // };
  setDisclaimerOpen() {
    this.setState({ isDisclaimerOpen: !this.state.isDisclaimerOpen });
  }

  getUtm() {
    // get url
    const url_string = window.location.href; //
    const url = new URL(url_string);

    if (url.searchParams.get("utm_campaign")) {
      return {
        utmCampaign: url.searchParams.get("utm_campaign"),
        utmSource: url.searchParams.get("utm_source"),
        utmMedium: url.searchParams.get("utm_medium"),
      };
    }
    return {};
  }

  // doSubmit = async () => {
  //     //console.log("Request form submitted");
  //     //console.log("Request form data", this.state.data);
  //     this.setState({loading: true});

  //     const {data} = this.state;

  //     const {model: currentModel, form} = this.props;
  //     data.model = `/models/${currentModel.id}`;

  //     if (data.city === "") {
  //         delete data.city;
  //     }

  //     if (data.telephone === "") {
  //         delete data.telephone;
  //     }

  //     const {utmSource, utmCampaign, utmMedium} = this.getUtm();
  //     const finalData = {...data, utmSource, utmMedium, utmCampaign};

  //     const apiHelper = new ApiResourceHelper(form.endpoint);

  //     try {

  //         console.log('thiiiiis is data',finalData)
  //         // const {data: request} = await apiHelper.create(finalData);
  //         //console.log("created");
  //         // const {onComplete, setRequest,} = this.props;
  //         // setRequest(request);
  //         // onComplete();
  //     } catch (er) {
  //         this.setState({loading: false});
  //     }

  // };

  doSubmit = async () => {
    this.setState({ loading: true });

    const { data } = this.state;

    const { model: currentModel, form } = this.props;

    data.model = `/models/${currentModel.id}`;

    if (data.city === "") {
      delete data.city;
    }

    if (data.telephone === "") {
      delete data.telephone;
    }

    const { utmSource, utmCampaign, utmMedium } = this.getUtm();
    const finalData = { ...data, utmSource, utmMedium, utmCampaign };

    const apiHelper = new ApiResourceHelper(form.endpoint);

    try {
      const { data: request } = await apiHelper.create(finalData);

      const { onComplete, setRequest } = this.props;
      setRequest(request);
      onComplete();
    } catch (er) {}

    this.setState({
      loading: false,
    });
  };

  getCities = async () => {
    const cityHelper = new ApiResourceHelper("/cities?published=1");
    const { data: cities } = await cityHelper.findAll();
    //console.log("cities", cities);

    this.setState({ cities });
  };

  getCity_list = () => {
    //console.log("this.state", this.state);
    const { cities } = this.state;

    const city_list = [{ key: "", value: null }];
    cities.map((city) => {
      city_list.push({
        key: `${city.name}`,
        value: `/cities/${city.id}`,
      });

      return true;
    });

    //console.log("city_list", city_list);

    return city_list;
  };
}

export default BrochureRequestForm;
