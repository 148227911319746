import React from "react";

import { Link } from "react-router-dom";
import { Col, Image, Row } from "react-bootstrap";
import { FaChevronLeft } from "react-icons/all";
import NoteFormation from "../NoteFormation";
import BrochureRequestForm from "../BrochureRequestForm";
import "../../../../assets/styles/form--fiat-pro--jett.css";
import styles from "../../../../pages/forms/brands/FiatForm/Fiat.module.css";
import Disclaimer from "../Disclamer/Disclamer";
import Loader from "react-loader-spinner";

class FiatBrochureRequestForm extends BrochureRequestForm {
  componentDidMount() {
    console.log("component mounted");
    this.getCities();

    const { form } = this.props;
    const { data } = this.state;

    console.log("requestform_form", form);

    // if (form.key === 'brochure') {
    //     delete this.schema.telephone;
    //     delete this.schema.city;
    //
    //     delete data.telephone;
    //     delete data.city;
    //
    //     this.setState({data});
    // }
  }

  render() {
    const { model, onModelChange, form } = this.props;
    const { loading } = this.state;

    return (
      <div>
        <div className="section-header title-form">
          <h2
            style={{
              fontFamily: "Open Sans, sans-serif",
              color: "#737373",
              textAlign: "left",
              fontSize: "22px",
              padding: "0 15px",
              textTransform: " capitalize",
              margin: "0",
              paddingLeft: "0",
            }}
          >
            Remplissez le formulaire
          </h2>
        </div>
        <Row>
          <div className={"col-lg-4"}>
            <span
              className={styles["title-form"]}
              style={{ fontFamily: "Open Sans, sans-serif", color: "#484848 " }}
            >
              Votre sélection :
            </span>
            {model && model.id && (
              <div>
                <p
                  className={styles["new-form-img-title"]}
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    color: "#484848 ",
                  }}
                >
                  {model.name}
                </p>
                <div className={"new-form-img"}>
                  <Image
                    src={`${process.env.REACT_APP_BASE_API}/uploads/images/${model.image.filePath}`}
                    className="img-fluid img-anagraphic w-100"
                  />
                  <Link
                    className={`${styles["new-form-link"]} ${styles["new-form-link-change"]} ${styles["model"]}`}
                    onClick={() => onModelChange()}
                  >
                    {/*<FaChevronLeft*/}
                    {/*    className={styles["new-icon-jeep_arrow_changemodel"]}*/}
                    {/*    style={{*/}
                    {/*        color: "#AD0C33"*/}
                    {/*    }}*/}
                    {/*/>*/}
                    Changer de modèle
                  </Link>
                </div>
              </div>
            )}
          </div>

          <div className={"col-lg-8"}>
            <div className={styles["form-anagrafic-container"]}>
              <form
                onSubmit={this.handleSubmit}
                className={`${styles["formAnagrafic"]} ${styles["mui-form"]} mt-2`}
              >
                <Row>
                  <div className={"col-xs-12 col-sm-6 mt-5"}>
                    <div
                      className={`${styles["new-select-form"]}  required-field`}
                    >
                      {this.renderSelect(
                        "gender",
                        "Civilité*",
                        [
                          { key: "", value: null },
                          { key: "Mr.", value: "mister" },
                          { key: "Mme.", value: "lady" },
                          { key: "Mlle.", value: "miss" },
                        ],
                        `${styles["dropdownSelect"]} ${styles["input-container"]}    `
                      )}
                    </div>
                  </div>
                </Row>

                <Row>
                  <Col md={6}>
                    {this.renderInput(
                      "firstName",
                      "text",
                      "Prénom*",
                      `mui--is-empty mui--is-pristine mui--is-touched`,
                      `mui-textfield mui-textfield--float-label required-field ${styles["input-container"]}`
                    )}
                  </Col>
                  <Col md={6}>
                    {this.renderInput(
                      "lastName",
                      "text",
                      "Nom*",
                      `mui--is-empty mui--is-pristine mui--is-touched`,
                      `mui-textfield mui-textfield--float-label required-field ${styles["input-container"]}`
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {this.renderInput(
                      "email",
                      "email",
                      "Email*",
                      `mui--is-empty mui--is-pristine mui--is-touched}`,
                      `mui-textfield mui-textfield--float-label required-field ${styles["input-container"]}`
                    )}
                  </Col>
                  {/*{form.key !== 'brochure' && (*/}
                  <Col md={6}>
                    {this.renderInput(
                      "telephone",
                      "tel",
                      "Téléphone*",
                      `mui--is-empty mui--is-pristine mui--is-touched}`,
                      `mui-textfield mui-textfield--float-label required-field ${styles["input-container"]}`
                    )}
                  </Col>
                  {/*)}*/}
                </Row>
                {/*{form.key !== 'brochure' && (*/}
                <Row>
                  <Col md={6}>
                    <div
                      className={`${styles["new-select-form"]} required-field`}
                    >
                      {this.renderSelect(
                        "city",
                        "Ville*",
                        this.getCity_list(),
                        `${styles["dropdownSelect"]} ${styles["input-container"]}`
                      )}
                    </div>
                  </Col>
                  {/*<Col md={6}></Col>*/}
                </Row>
                {/*)}*/}
                <Row>
                  <div className="col-xs-12 col-sm-6 pb-lg-3">
                    <small className={styles["fs-small"]}>
                      * Tous les champs sont obligatoires
                    </small>
                  </div>
                </Row>
                <Row>
                  <div className={"col-xs-12"}>
                    <div className={"new-form-text"}>
                      <div className={`${styles["new-form-text-title"]} mt-3`}>
                        CONSENTEMENT
                      </div>
                      <div className={styles["new-form-text-content"]}>
                        Après avoir lu la
                        <NoteFormation />
                      </div>
                      <div
                        className={
                          "d-flex align-items-center justify-content-start"
                        }
                      >
                        {this.renderRadioInput(
                          "acceptedMarketingActivity",
                          " ",
                          [
                            { key: "J’accepte", value: true },
                            { key: "Je refuse", value: false },
                          ],
                          `${styles["form-check-input"]}`,
                          "",
                          {}
                        )}

                        <a
                          onClick={() => this.setDisclaimerOpen()}
                          aria-controls={styles["note-infos"]}
                          aria-expanded={this.state.isDisclaimerOpen}
                          className={`${styles["new-form-linktext"]} m-0`}
                        >
                          <u>Conditions générales d'utilisation</u>
                        </a>
                      </div>
                    </div>
                    <Disclaimer open={this.state.isDisclaimerOpen} />
                  </div>
                </Row>

                <div className={"col-xs-12"}>
                  <div
                    className={`${styles["new-form-submit"]} ${styles["submit-fiat"]}`}
                    style={{ textAlign: "right" }}
                  >
                    {!loading ? (
                      this.renderButton("ÉTAPE SUIVANTE")
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <Loader
                          type="TailSpin"
                          color="#00BFFF"
                          height={100}
                          width={100}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}

export default FiatBrochureRequestForm;
