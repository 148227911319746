import React, { Component } from "react";
import ApiResourceHelper from "../../../../helpers/ApiResourceHelper";
import forms from "../../../../config/forms.json";
import genders from "../../../../config/genders.json";
import axios from "axios";

class RequestThankyou extends Component {
  state = {
    request: {},
    loaded: false,
  };

  componentDidMount() {
    this.getRequest();
  }

  getCurrentForm = () => {
    const formKey = this.props.match.params.form;
    return forms.filter((f) => f.key === formKey)[0];
  };

  getRequest = async () => {
    const form = this.getCurrentForm();

    const requestId = this.props.match.params.request;
    const iri = `${form.endpoint}/${requestId}`;

    const apiHelper = new ApiResourceHelper(form.endpoint);
    const { data } = await apiHelper.findOne(iri);
    console.log("request, state", data, this.state);
    console.log("props", this.props);
    const url_string = window.location.href;
    const url = new URL(url_string);

    const greeting = {
      mister: "M.",
      lady: "Mme.",
      miss: "Mlle.",
    };

    const RequestTypeMapping = {
      devis: "Devis commercial",
      brochure: "Infos",
      essai: "Demande de Test Drive",
    };

    // const description = sessionStorage.getItem("description");

    // console.log("description", description);

    const payload = {
      greeting: greeting[data.gender], //{'M.', 'Mme.','Mlle.','Dr.','Prof.'}
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.telephone,
      email: data.email,
      brand: data.model.brand.name,
      model: data.model.name,
      city: data.city.name,
      source: "website", // { 'website','chatbot','landing_page','event_website','bol','ocarz'}
      formSource: window.location.href,
      requestType: RequestTypeMapping[this.props.match.params.form],
      showroom: data.showroom?.code,
      dealer: data.showroom?.name,
      utmCampaign: url.searchParams.get("utm_campaign"),
      utmSource: url.searchParams.get("utm_source"),
      utmMedium: url.searchParams.get("utm_medium"),
      description:
        data.model && data.model.description.trim() !== ""
          ? data.model.description
          : undefined,
    };

    console.log("payload", payload);

    try {
      // send lead to salesforce
      axios
        .post("https://fca-salesforce-hub.fcamaroc.net/leads", payload)
        .then(function (response) {
          console.log("response", response);
        })
        .catch(function (error) {
          console.log("error", error);
        });
    } catch (er) {}

    this.setState({ request: data, loaded: true });
  };

  getGender = (key) => {
    const gender = genders.filter((f) => f.key === key)[0];
    console.log("gender", gender);

    return gender;
  };
}

export default RequestThankyou;
