import React from "react";
import { Container, Image } from "react-bootstrap";
import styles from "../../../../pages/forms/brands/FiatForm/Fiat.module.css";
import RequestModelList from "../RequestModelList";

class FiatRequestModelList extends RequestModelList {
  brandSlug = "fiat";

  render() {
    const { loaded } = this.state;
    console.log("this.props fiat", this.props);
    const { onModelClick } = this.props;

    const excludedModels = [
      "topolino-dolcevita",
      "tipo-street-edition",
      "fiat-500c",
    ];
    const models = this.getFilteredModels().filter(
      (m) => !excludedModels.includes(m.slug)
    );

    // console.log("models", models);

    if (loaded) {
      return (
        <div>
          <div
            className={`${styles["section-header"]} ${styles["form-models-list"]}`}
          >
            <h2> Sélectionnez un modèle</h2>
          </div>
          <div className={styles["new-form-step-content-list-flex"]}>
            {models.map((m) => (
              <div
                className={`${styles["new-form-step-content-list-item"]} ${styles["wrap-car-model"]}`}
                key={m.id}
              >
                <div
                  className={styles["new-form-vehicle"]}
                  onClick={() => onModelClick(m)}
                >
                  <Image
                    src={
                      m.image &&
                      `${process.env.REACT_APP_BASE_API}/uploads/images/${m.image.filePath}`
                    }
                    className={`img-fluid ${styles["new-form-vehicle-image"]}`}
                  />
                  <div className={styles["new-form-vehicle-title"]}>
                    {" "}
                    {m.name}{" "}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

export default FiatRequestModelList;
